import Analytics from 'analytics'

window.analytics = Analytics({
  app: 'trunk',
  plugins: [
    {
      name: 'trunk',
      track: ({ payload }) => {
        const eventPayload = {
          userId: payload.userId,
          anonymousId: payload.anonymousId,
          event: payload.event,
          properties: payload.properties,
          timestamp: payload.meta.ts / 1000,
          writeKey: TRUNK_CREDENTIALS.analyticsWriteKey,
        }

        fetch(`${TRUNK_ROUTES.sinkUrl}/analytics/track`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(eventPayload),
        })
      },
      identify: ({ payload }) => {
        // Don't need to identify via backend for now
      },
      page: ({ payload }) => {
        // Don't track page visits for now
      },
    },
  ],
})
